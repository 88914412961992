import * as echarts from 'echarts';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['chartdom'];
  static values = { series: Object, options: Object };

  connect() {
    var chartDom = this.element;
    var myChart = echarts.init(chartDom);
    var option;

    const data = this.seriesValue['data'];
    const legend_data = data.map((item) => item.name);
    const title = this.optionsValue['title'];

    option = {
      title: {
        text: title,
      },
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c}%',
      },
      toolbox: {
        feature: {},
      },
      legend: {
        data: legend_data,
      },
      series: [
        {
          name: title,
          type: 'funnel',
          left: '10%',
          top: 60,
          bottom: 60,
          width: '80%',
          min: 0,
          max: 100,
          minSize: '0%',
          maxSize: '100%',
          orient: this.optionsValue['orient'],
          gap: 0,
          sort: 'none',
          label: {
            show: true,
            position: 'inside',
            formatter: '{b} : {c}%',
          },
          labelLine: {
            length: 10,
            lineStyle: {
              width: 1,
              type: 'solid',
            },
          },
          itemStyle: {
            borderColor: '#fff',
            borderWidth: 1,
          },
          emphasis: {
            label: {
              fontSize: 20,
            },
          },
          data: data,
        },
      ],
    };

    option && myChart.setOption(option);
  }
}
