import { Controller } from 'stimulus';
import debounced from 'debounced';

export default class extends Controller {
  static targets = ['input', 'form'];

  connect() {
    debounced.initialize({ input: { wait: 500 }, keyup: { wait: 500 }, "trix-change": { wait: 100 }, change: { wait: 600 } });
    if(this.hasInputTarget) {
      this.inputTargets.forEach(inputTarget => {
        if ((inputTarget instanceof HTMLInputElement) || inputTarget instanceof HTMLTextAreaElement) {
          if (typeof inputTarget.setSelectionRange === 'function' && inputTarget.type == 'text') {
            inputTarget.setSelectionRange(
              inputTarget.value.length,
              inputTarget.value.length
            );
          }
        }
      });
    }
  }

  save() {
    this.formTarget.requestSubmit();
  }
}
