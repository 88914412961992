import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="bstooltip"
export default class extends Controller {
  static values = { title: String, placement: String }
  connect() {
    this.tooltip = new bootstrap.Tooltip(this.element, {
      title: this.titleValue,
      placement: this.hasPlacementValue ? this.placementValue : 'top'
    })
    this.tooltip.enable()
  }

  disconnect() {
    this.tooltip.disable()
  }
}
