import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["section"];

  revealSection(event) {
    const sectionName = event.currentTarget.dataset.visibilitySectionParam;
    this.sectionTargets.forEach((sectionTarget) => {
      if (sectionTarget.dataset.sectionName === sectionName) {
        sectionTarget.classList.remove('d-none');
        if (sectionTarget.dataset.formId) {
          const form = document.getElementById(sectionTarget.dataset.formId);
          if (form) {
            form.requestSubmit();
          }
        }
      } else {
        sectionTarget.classList.add('d-none');
      }
    });
  }
}