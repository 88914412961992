import { Controller } from 'stimulus';
import * as Turbo from '@hotwired/turbo';

export default class extends Controller {
  static values = { forceVisit: Boolean };

  connect() {
    this.element.querySelectorAll('.nav-item').forEach((navItem) => {
      navItem.addEventListener('click', (e) => {
        this.onTabClick(e);
      });
    });
  }

  onTabClick(e) {
    let navLink = e.target.closest('[data-bs-toggle="tab"]');
    if (navLink && navLink.id) {
      window.history.replaceState(
        null,
        null,
        `?tab=${navLink.id.replace('-tab', '')}`
      );
    }

    if (this.hasForceVisitValue && this.forceVisitValue) {
      Turbo.visit(window.location.href, { action: 'replace' });
    }
  }
}
