// app/javascript/controllers/clear_input_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input"]

  clear(event) {
    // event.preventDefault();
    this.inputTarget.value = "";
  }
}