import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['tab'];
  static values = { parameter: String };

  connect() {
    let selection = this.findSelectedTab(new URL(window.location.href));
    if (!selection) {
      selection = this.element.dataset.tabsDefaultValue;
    }
    this.refreshTabSelection(selection);
    this.refreshTabContents(selection);
    this.addTabClickListeners();
  }

  setActiveTab(selection) {
    this.showTabLoadingIndicator(selection);
    this.refreshTabSelection(selection);
    this.refreshTabContents(selection);
  }

  findSelectedTab(parent_url) {
    return parent_url.searchParams.get(this.parameterValue);
  }

  showTabLoadingIndicator(selection) {
    let spinner = document
      .querySelector('#tab-loading-spinner div')
      .cloneNode(true);
    let listEl = document.querySelector(
      '#conversations_' + selection + '_list'
    );
    if (listEl && spinner) {
      listEl.insertBefore(spinner, listEl.firstChild);
    }
  }

  refreshTabSelection(selection) {
    if (selection === '' || selection == null) return;
    for (var i = 0, len = this.tabTargets.length; i < len; i++) {
      if (this.tabTargets[i].getAttribute('data-selection') == selection) {
        this.tabTargets[i].classList.add('active');
      } else {
        this.tabTargets[i].classList.remove('active');
      }
    }
  }

  refreshTabContents(selection) {
    let tabs = ['open', 'closed', 'unassigned'];
    tabs.forEach((tab) => {
      let listFrame = document.querySelector(
        'turbo-frame[data-conversation-list-target="' + tab + '"]'
      );
      if (tab == selection && listFrame) {
        listFrame.classList.remove('d-none');
        listFrame.classList.add('active');
      } else if (listFrame) {
        listFrame.classList.remove('active');
        listFrame.classList.add('d-none');
      }
    });
  }

  addTabClickListeners() {
    let self = this;
    this.tabTargets.forEach((e) => {
      e.addEventListener('click', (evnt) => {
        if (!evnt.target.href) {
          evnt.preventDefault();
          return false;
        }
        let newUrl = new URL(evnt.target.href);
        window.history.pushState({}, '', newUrl);
        self.setActiveTab(self.findSelectedTab(newUrl));
      });
    });
  }
}
