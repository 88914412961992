import { Controller } from 'stimulus';
import { post, get, patch } from '@rails/request.js'

export default class extends Controller {
  static targets = [
    'chatMessage',
    'cannedMessageContent',
    'fileContent',
    'messageBox',
    'quickResponseSelect',
    'submitFormButton',
  ];

  static values = {
    aiSuggestionUrl: String,
  };

  addCannedMessage(e) {
    const newValue = e.currentTarget.value;
    this.chatMessageTarget.value = newValue;
    this.cannedMessageToggle();
  }

  sendButton(e) {
    if (e.keyCode == 13 && (e.ctrlKey || e.altKey)) {
      e.target.value = e.target.value + "\n";
    } else if (e.keyCode == 13) {
      e.target.setAttribute('readonly', '');
      this.submitFormButtonTarget.click();
      e.target.value = '';
    }
  }

  fileToggle() {
    if (this.fileContentTarget.classList.contains('show')) {
      this.fileContentTarget.classList.remove('show');
      this.messageBoxTarget.classList.add('show');
    } else {
      this.fileContentTarget.classList.add('show');
      this.messageBoxTarget.classList.remove('show');
      if (this.cannedMessageContentTarget) {
        this.cannedMessageContentTarget.classList.remove('show');
      }
    }
  }

  switchQuickResponse() {
    if (this.quickResponseSelectTarget.value) {
      document.querySelectorAll('textarea.quick-response-txt').forEach((e) => {
        if (!e.classList.contains('collapse')) {
          e.classList.add('collapse');
        }
      });
      let quickResponseTxt = document.querySelector(
        'textarea[data-quick-response-id=\'' +
        this.quickResponseSelectTarget.value +
        '\']'
      );
      quickResponseTxt.classList.remove('collapse');
      this.submitFormButtonTarget.disabled = false;
    } else {
      this.submitFormButtonTarget.disabled = true;
      document.querySelectorAll('textarea.quick-response-txt').forEach((e) => {
        if (!e.classList.contains('collapse')) {
          e.classList.add('collapse');
        }
      });
    }
  }

  cannedMessageToggle() {
    if (this.cannedMessageContentTarget.classList.contains('show')) {
      this.cannedMessageContentTarget.classList.remove('show');
      this.messageBoxTarget.classList.add('show');
    } else {
      this.cannedMessageContentTarget.classList.add('show');
      this.fileContentTarget.classList.remove('show');
      this.messageBoxTarget.classList.remove('show');
    }
  }

  async getAiSuggestion(e) {
    console.log(e.target.classList.contains('disabled'));
    if(e.target.classList.contains('disabled')) return;

    e.target.classList.add('disabled');
    e.target.innerHTML = 'Generating...';
    if(this.hasAiSuggestionUrlValue) {
      await get(this.aiSuggestionUrlValue, { responseKind: 'turbo-stream' });
    }
  }
}
