import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['value'];

  mediaSelected(e) {
    const mediaId = e.detail.media_id;
    this.valueTarget.value = mediaId;
    this.valueTarget.form.requestSubmit();
  }
}
