import { Controller } from 'stimulus';
import { patch, get } from '@rails/request.js';

export default class extends Controller {
  static targets = [
    'conversationFilter',
    'conversationStatus',
    'conversationsList',
    'statusModal',
    'statusField',
    'validateFormFields',
    'selector',
  ];
  static values = { url: String };

  connect() {
    if (this.element.getAttribute('data-trigger') == 'onload') {
      if (this.element.getAttribute('data-action') == 'navigateToNextIn') {
        this.navigateToNextIn(
          this.element.getAttribute('data-navigation-param')
        );
      }
    }
  }

  async addAssignedTo(e) {
    await patch(e.target.dataset.url, {
      responseKind: 'turbo-stream',
      body: JSON.stringify({
        conversation: {
          assigned_to_id: e.target.dataset.userId,
          assigned_to_type: e.target.dataset.userType,
          status: e.target.dataset.conversationStatus,
        },
      }),
    });
    this.closeAssignmentPanel();
  }

  async removeAssignedTo(e) {
    await patch(e.target.dataset.url, {
      responseKind: 'turbo-stream',
      body: JSON.stringify({
        conversation: {
          assigned_to_id: null,
          assigned_to_type: null,
        },
      }),
    });
    this.closeAssignmentPanel();
  }

  async removeAssignedToGroup(e) {
    await patch(e.target.dataset.url, {
      responseKind: 'turbo-stream',
      body: JSON.stringify({
        conversation: {
          groups_attributes: { id: e.target.dataset.groupId, _destroy: true },
        },
      }),
    });
    this.closeAssignmentPanel();
  }

  async addAssignedToGroup(e) {
    await patch(e.target.dataset.url, {
      responseKind: 'turbo-stream',
      body: JSON.stringify({
        conversation: {
          group_ids: [e.target.dataset.groupId],
        },
      }),
    });
    this.closeAssignmentPanel();
  }

  getSelectedConversations() {
    let selectedConversations = [];
    if (this.hasSelectorTarget) {
      selectedConversations = JSON.parse(
        this.selectorTarget.dataset.checkboxSelectAllSelectedValue
      );
    }
    return selectedConversations;
  }

  async showDetails(e) {
    let conversationIds = this.getSelectedConversations();
    let target_url = e.target.dataset.url;
    if (conversationIds.length > 0) {
      target_url = target_url + '?id_in=' + conversationIds.join(',');
    }

    await get(target_url, {
      responseKind: 'turbo-stream',
    });
  }

  async navigateToNextIn(selector) {
    if (selector == null || selector == '' || selector == undefined) {
      return;
    }
    let conversationIds = [];
    document.querySelectorAll(selector).forEach((e) => {
      conversationIds.push(e.id);
    });
    if (conversationIds.length == 0) {
      document.querySelectorAll("#conversations .conversation-listing").forEach((e) => {
        conversationIds.push(e.id);
      });
    }
    let index = 0;
    if (this.element && this.element.dataset.previousConversationId) {
      index = conversationIds.findIndex((e, i) => {
        return e == "conversation_" + this.element.dataset.previousConversationId;
      });
      index = index + 1;
      if (conversationIds.length == 0 || index >= conversationIds.length) {
        index = 0;
      } else if (index + 1 >= conversationIds.length) {
        index = conversationIds.length - 1;
      }
    } else {
      index = 0;
    }
    if (conversationIds.length > 0) {
      let link = document
        .getElementById(conversationIds[index])
        .querySelector('a[data-controller=\'turbo-fetch\']');
      if (link) {
        await get(link.getAttribute('href'), {
          responseKind: 'turbo-stream',
        });
      }
    }
  }

  async navigateToNextUnassigned() {
    this.navigateToNextIn(
      '.conversations_container.active .conversation-listing'
    );
  }

  async navigateToNextConversation() {
    this.navigateToNextIn('.conversation-listing');
  }

  async launchStatusModal(e) {
    let modalController = this.application.getControllerForElementAndIdentifier(
      this.statusModalTarget,
      'modal'
    );
    modalController.open();
    await get(e.target.dataset.url, {
      responseKind: 'turbo-stream',
    });
  }

  closeStatusModal() {
    let modal = document.querySelector(
      '[data-conversation-target=\'statusModal\']'
    );
    modal.classList.add('hidden');
    modal.classList.remove('show');
    modal.setAttribute('aria-hidden', 'true');
    document.querySelector('body').classList.remove('modal-open');
    if (document.querySelector('.modal-backdrop')) {
      document.querySelector('.modal-backdrop').remove();
    }
  }

  closeAssignmentPanel() {
    let panel = document.querySelector('.offcanvas.show');
    if (panel) {
      panel.classList.remove('show');
    }
  }

  conversationStatusChange(e) {
    e.preventDefault();
    if (e.target.dataset.cafpresent == 'true') {
      this.launchStatusModal(e);
    } else {
      let status = e.target.dataset.status;
      this.statusFieldTarget.value = status;
      this.conversationStatusTarget.dispatchEvent(
        new CustomEvent('submit', { bubbles: true })
      );
    }
  }

  validateForm() {
    var validForm = true;
    this.validateFormFieldsTarget
      .querySelectorAll('[required]')
      .forEach((el) => {
        validForm = validForm && this.customValidation(el, el.dataset.rulename);
      });
    if (validForm) {
      this.closeStatusModal();
      this.validateFormFieldsTarget.dispatchEvent(
        new CustomEvent('submit', { bubbles: true })
      );
    }
  }

  customValidation(element, rulename) {
    var validField = true;
    var value = element.value;
    var el = this.validateFormFieldsTarget.querySelector(
      element.dataset.errortarget
    );
    switch (rulename) {
      case 'textvalidate':
        if (value.length == 0) {
          validField = false;
          el.classList.remove('d-none');
        } else {
          validField = true;
          el.classList.add('d-none');
        }
        break;
      case 'numbervalidate':
        if (value.length == 0) {
          validField = false;
          el.classList.remove('d-none');
        } else {
          validField = true;
          el.classList.add('d-none');
        }
        break;
      case 'selectvalidate':
        if (value.length == 0) {
          validField = false;
          el.classList.remove('d-none');
        } else {
          validField = true;
          el.classList.add('d-none');
        }
        break;
      case 'booleanvalidate':
        if (element.checked) {
          validField = true;
          el.classList.add('d-none');
        } else {
          validField = false;
          el.classList.remove('d-none');
        }
        break;
      case 'emailvalidate':
        /* eslint-disable */
        var re =
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        /* eslint-disable */
        if (re.test(value)) {
          validField = true;
          el.classList.add('d-none');
        } else {
          validField = false;
          el.classList.remove('d-none');
        }
        break;
    }
    return validField;
  }

  filterToggle() {
    if (this.conversationFilterTarget.classList.contains('show')) {
      this.conversationFilterTarget.classList.remove('show');
    } else {
      this.conversationFilterTarget.classList.add('show');
    }
  }
}
