import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['item'];
  static classes = ['selectedItem'];

  selected(event) {
    this.itemTargets.forEach((item) => {
      item.classList.remove(this.selectedItemClass);
    });

    event.currentTarget.classList.add(this.selectedItemClass);
  }
}
