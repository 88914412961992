import { Controller } from 'stimulus';

export default class extends Controller {
  static values = { input: String}

  clicked(e){
    // app/views/wa/ai_flows/tests/messages/_form.html.erb
    const form = document.getElementById("new_message");
    const input = document.getElementById("message_text");

    const buttonValue = this.inputValue;

    input.value = buttonValue;
    form.requestSubmit();
  }
}