import { Controller } from "@hotwired/stimulus"
import "ninja-keys"
import "hotkeys-js"
import { get } from '@rails/request.js'

export default class extends Controller{
  connect(){
    this.element.data = [
      {
        id: 'Apps',
        title: 'Apps',
        hotkey: 'g + a',
        mdIcon: 'apps',
        section: 'Goto',
        handler: () => {
          this.open('/wa/flows')
        }
      },
      {
        id: 'Draft Apps',
        title: 'Draft Apps',
        hotkey: 'g + d',
        mdIcon: 'apps',
        section: 'Goto',
        handler: () => {
          this.open('/wa/flows/drafts')
        }
      },
      {
        id: 'Broadcasts',
        title: 'Broadcasts',
        hotkey: 'g + b',
        mdIcon: 'send',
        section: 'Goto',
        handler: () => {
          this.open('/wa/campaigns')
        }
      },
      {
        id: 'Chat',
        title: 'Chat',
        hotkey: 'g + i',
        mdIcon: 'chat',
        section: 'Goto',
        handler: () => {
          this.open('/conversations')
        }
      },
      
      {
        id: 'Change Conversation Status to Open',
        title: 'Change Conversation Status to Open',
        parent: 'Chat',
        section: 'Chat',
        hotkey: 'shift+O',
        handler: () => {
          document.querySelector('.js-open-conversation').click();
        }
      },
      {
        id: 'Change Conversation Status to Closed',
        title: 'Change Conversation Status to Closed',
        parent: 'Chat',
        section: 'Chat',
        hotkey: 'shift+C',
        handler: () => {
          document.querySelector('.js-close-conversation')?.click();
        }
      },
      {
        id: 'Change Conversation Status to Pending',
        title: 'Change Conversation Status to Pending',
        parent: 'Chat',
        section: 'Chat',
        hotkey: 'shift+P',
        handler: () => {
          document.querySelector('.js-pending-conversation')?.click();
        }
      },
      {
        id: 'Change Conversation Status to Awaiting Response',
        title: 'Change Conversation Status to Awaiting Response',
        parent: 'Chat',
        section: 'Chat',
        hotkey: 'shift+A',
        handler: () => {
          document.querySelector('.js-awaiting-response-conversation')?.click();
        }
      },
      {
        id: 'Assign To Me',
        title: 'Assign To Me',
        parent: 'Chat',
        section: 'Assign To',
        hotkey: 'shift+N+M',
        handler: () => {
          document.querySelector('.js-assign-to-me')?.click();
        }
      },
      {
        id: 'Assign To Others',
        title: 'Assign To Others',
        parent: 'Chat',
        section: 'Assign To',
        hotkey: 'shift+N+O',
        handler: () => {
          document.querySelector('.js-assign-to-others')?.click();
        }
      },
      {
        id: 'Contacts',
        title: 'Contacts',
        hotkey: 'g + c',
        mdIcon: 'contacts',
        section: 'Goto',
        handler: (e) => {
          this.open('/wa/subscribers')
        }
      },
      {
        id: 'Templates',
        title: 'Templates',
        hotkey: 'g + t',
        section: 'Goto',
        handler: () => {
          this.open('/wa/templates')
        }
      },
      {
        id: 'Settings',
        title: 'Settings',
        hotkey: 'g + s',
        section: 'Goto',
        handler: () => {
          this.open('/account')
        }
      },
      {
        id: 'Create App',
        title: 'Create App',
        parent: 'Create',
        hotkey: 'c + a',
        section: 'Create',
        handler: () => {
          this.get('/wa/flows/new')
        }
      },
      {
        id: 'Create Broadcast',
        title: 'Create Broadcast',
        parent: 'Create',
        hotkey: 'c + b',
        section: 'Create',
        handler: () => {
          this.get('/wa/campaigns/new')
        }
      },
      {
        id: 'Create Contact',
        title: 'Create Contact',
        parent: 'Create',
        hotkey: 'c + c',
        section: 'Create',
        handler: () => {
          this.get('/wa/subscribers/new')
        }
      },
      {
        id: 'Create Template',
        title: 'Create Template',
        parent: 'Create',
        hotkey: 'c + t',
        section: 'Create',
        handler: () => {
          this.get('/wa/templates/new')
        }
      }
    ]

    this.registerChord();
  }

  open(href) {
    window.location.href = href
  }

  async get(url) {
    const response = await get(url, {
        responseKind: 'turbo-stream'
    });
  }

  registerChord() {
    var chordScope = 'chord'

    var chord = {
        handler: null, // The hotkeys handler initiating the chord sequence
        timeout: null, // The chord timeout
    }

    var resetChord = function() {
        hotkeys.setScope(chordScope)
        clearTimeout(chord.timeout)
        chord.handler = null
        chord.timeout = null
    }

    hotkeys('g, c, ctrl+a', chordScope, function (event, handler) {
        hotkeys.setScope('g-')
        chord.handler = handler
        chord.timeout = setTimeout(function() {
            console.log('Stop waiting for second key after '+chord.handler.key)
            resetChord()
        }, 2000)
        console.log('You pressed '+handler.key+'. Waiting for second key i chord …')
    })
    hotkeys('a, b, c, i, t, d', 'g-', (event, handler) => {
        console.log('You pressed '+chord.handler.key+' followed by '+handler.key)
        switch (chord.handler.key) {
          case 'g':
            switch (handler.key) {
                case 'a':
                  this.open('/wa/flows')
                  break
                case 'b':
                  this.open('/wa/campaigns')
                  break
                case 'c':
                  this.open('/wa/subscribers')
                  break
                case 'd':
                  this.open('/wa/flows/drafts')
                  break
                case 'i':
                  this.open('/conversations')
                  break
                case 't':
                  this.open('/wa/templates')
                  break
            }
            break
          case 'c':
            switch (handler.key) {
              case 'a':
                this.open('/wa/flows/new')
                break
              case 't':
                this.get('/wa/templates/new')
                break
              case 'b':
                this.get('/wa/campaigns/new')
                break
              case 'c':
                this.get('/wa/subscribers/new')
                break
            }
            break
      }
        resetChord()
    })

    hotkeys.setScope(chordScope)
  }
}