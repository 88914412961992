import { Controller } from "stimulus";

export default class extends Controller {
  static values = { solutionId: String };

  open360DialogPermissionScreen() {
    const windowFeatures =
      'toolbar=no, menubar=no, width=600, height=900, top=100, left=100';
    open(
      'https://hub.360dialog.com/dashboard/app/uXt6vjPA/permissions',
      'integratedOnboardingWindow',
      windowFeatures
    );
  }

  openMetaDialogScreen() {
    this.hideAlert();
    window.addEventListener('message', this.sessionInfoListener.bind(this));  // Add event listener to listen to the session info
    let configuration = {
      config_id: '1393833954534708', // configuration ID obtained in the previous step goes here
      response_type: 'code',     // must be set to 'code' for System User access token
      override_default_response_type: true,
      extras: {
        "feature": "whatsapp_embedded_signup",
        "sessionInfoVersion": 3,
        setup: {
          // Prefilled data can go here
        }
      }
    }

    // Add solution id to the configuration extras setup object
    if(this.hasSolutionIdValue && this.solutionIdValue){
      configuration.extras.setup.solutionID = this.solutionIdValue;
    }

    if (typeof FB !== 'undefined') {
      FB.login((response) => {
        const code = response?.authResponse?.code;
        if (code && this.phone_number_id && this.waba_id) {
          this.showAlert("Connecting your phone number... Please do not close or refresh the page.");
          // Use this token to call the debug_token API and get the shared WABA's ID
          window.location.href = "/account/integrations/whatsapp?code=" + code + 
                                 "&phone_number_id=" + this.phone_number_id + 
                                 "&waba_id=" + this.waba_id;
        } else {
          this.showAlert("Couldn't connect your phone number. Please try again.");
          console.error("Missing code, phone_number_id, or waba_id");
        }
      }, configuration);
    } else {
      console.error("FB object is not defined");
    }
  }

  showAlert(text) {
    document.querySelector('#phone-alert').classList.remove('d-none');
    document.querySelector('#phone-alert-text').innerText = text;
  }

  hideAlert() {
    document.querySelector('#phone-alert').classList.add('d-none');
    document.querySelector('#phone-alert-text').innerText = "";
  }

  sessionInfoListener(event) {
    if (event.origin == null) {
      return;
    }
    
    // Make sure the data is coming from facebook.com
    if (!event.origin.endsWith("facebook.com")) {
      return;
    }
    
    try {
      const data = JSON.parse(event.data);
      if (data.type === 'WA_EMBEDDED_SIGNUP') {
        // if user finishes the Embedded Signup flow
        if (data.event === 'FINISH') {
          const {phone_number_id, waba_id} = data.data;
          this.phone_number_id = phone_number_id;
          this.waba_id = waba_id;
          console.log("Phone number ID ", phone_number_id, " WhatsApp business account ID ", waba_id);
        }
        // if user reports an error during the Embedded Signup flow
        else if (data.event === 'ERROR') {
          const {error_message} = data.data;
          console.error("error ", error_message);
        }
        // if user cancels the Embedded Signup flow
        else {
         const{current_step} = data.data;
         console.warn("Cancel at ", current_step);
        }
      }
    } catch {
      // Don’t parse info that’s not a JSON
      console.log('Non JSON Response', event.data);
    }
  };
}