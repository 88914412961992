import { Controller } from 'stimulus';
import { useDispatch } from 'stimulus-use';

export default class extends Controller {
  static values = { url: String, mediaId: String, blockIndex: Number };

  connect() {
    useDispatch(this);
  }

  onSelect() {
    let params = { selected_url: this.urlValue, media_id: this.mediaIdValue };
    if (this.hasBlockIndexValue) {
      params.block_index = this.blockIndexValue;
    }
    this.dispatch('selected', params);
  }
}
