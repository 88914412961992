import { Controller } from 'stimulus';
import debounced from 'debounced';

export default class extends Controller {
  static targets = ['searchInput', 'searchForm'];

  connect() {
    debounced.initialize({ input: { wait: 500 }, keyup: { wait: 500 }, "trix-change": {wait: 100}});
    if (this.hasSearchInputTarget && (this.searchInputTarget instanceof HTMLInputElement || this.searchInputTarget instanceof HTMLTextAreaElement)) {
      if (typeof this.searchInputTarget.setSelectionRange === 'function') {
        this.searchInputTarget.setSelectionRange(
          this.searchInputTarget.value.length,
          this.searchInputTarget.value.length
        );
      }
    }
  }

  search() {
    this.searchFormTarget.requestSubmit();
  }
}
